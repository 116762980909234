<template>
    <div class="main-box">
        <div class="pd-box">
            <div class="title">
                <p>
                    <span style="color: #CECECE;">企业诊断 > </span>
                    <span style="color: #CECECE;">诊断历史 > </span>
                    <span>诊断详情</span>
                </p>
            </div>
            <div class="content-history">
                <div style="display: flex;height: 40px;line-height: 40px;">
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">诊断时间:&nbsp;</div>
                        <div style="width: 50%;">{{detail.EXEDATE}}</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">综合评分:&nbsp;</div>
                        <div style="width: 50%;">{{detail.ZONGHEFS}}分</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">授信额度:&nbsp;</div>
                        <div style="width: 50%;">{{detail.SHOUXINEDU}}万元</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">关键人信息击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.GJRXX}}个</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">行政处罚击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.XZCF}}个</div>
                    </div>
                </div>
                <div style="display: flex;height: 40px;line-height: 40px;">
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">异常名录击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.YCML}}个</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">清算信息击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.QSXX}}个</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">动产抵押击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.DCDY}}个</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">司法情况击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.SFQK}}个</div>
                    </div>
                    <div style="width: 20%;display: flex;">
                        <div style="width: 50%;text-align: right;color: #B1B1B1;">信用情况击中:&nbsp;</div>
                        <div style="width: 50%;">{{detail.XYQK}}个</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>关键人信息</p>
            </div>
            <div class="content-history">
                <div v-if="kpList.length != 0">
                    <a-table :bordered="true" :columns="columnsKP" :data-source="kpList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
                </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>行政处罚信息</p>
            </div>
            <div class="content-history">
                <div v-if="adList.length != 0">
                    <a-table :bordered="true" :columns="columnsAD" :data-source="adList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
                </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>异常名录信息</p>
            </div>
            <div class="content-history">
                <div v-if="abList.length != 0">
                    <a-table :bordered="true" :columns="columnsAB" :data-source="abList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
                </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>清算信息</p>
            </div>
            <div class="content-history">
                <div v-if="clList.length != 0">
                    <a-table :bordered="true" :columns="columnsCL" :data-source="clList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
                </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>动产抵押信息</p>
            </div>
            <div class="content-history">
                <div v-if="chList.length != 0">
                    <a-table :bordered="true" :columns="columnsCH" :data-source="chList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
                </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>司法信息</p>
            </div>
            <div class="content-history">
                <div v-if="juList.length != 0">
                    <a-table :bordered="true" :columns="columnsJU" :data-source="juList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
                </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
        <div class="pd-box">
            <div class="title">
                <p>信用信息</p>
            </div>
            <div class="content-history">
              <div v-if="ceditList.length != 0">
                <a-table :bordered="true" :columns="columnsCT" :data-source="ceditList" :pagination="false" style="margin-top: 20px;margin-bottom: 20px;"></a-table>
              </div>
                <div style="text-align: center;" v-else>
                    <img src="../assets/images/no-data.png">
                </div>
            </div>
        </div>
      <div class="pd-box">
        <a-button style="margin-left: 50%" type="primary" @click="fh">返回</a-button>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import login from "@/store/modules/login/login";
const columnsJU = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '标题', align: 'center', dataIndex: 'biaoti', key: 'biaoti' },
  { title: '审结时间', align: 'center', dataIndex: 'shenjieshijian', key: 'shenjieshijian' },
  { title: '注册类型', align: 'center', dataIndex: 'zhuceleixing', key: 'zhuceleixing' },
  { title: '数据类型', align: 'center', dataIndex: 'shujuleixing', key: 'shujuleixing' },
  { title: '匹配度',align: 'center', dataIndex: 'pipeidu', key: 'pipeidu' },
  { title: '内容概要', align: 'center', dataIndex: 'neironggaiyao', key: 'neironggaiyao' }
]
const columnsAD = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '决定书文号', align: 'center', dataIndex: 'juedingshuwenhao', key: 'juedingshuwenhao' },
  { title: '违法行为类型', align: 'center', dataIndex: 'weifaxingweileixing', key: 'weifaxingweileixing' },
  { title: '处罚种类', align: 'center', dataIndex: 'chufazhonglei', key: 'chufazhonglei' },
  { title: '行政处理内容', align: 'center', dataIndex: 'xingzhengchulineirong', key: 'xingzhengchulineirong' },
  { title: '决定机关名称',align: 'center', dataIndex: 'juedingjiguanmingcheng', key: 'juedingjiguanmingcheng' },
  { title: '处罚决定日期', align: 'center', dataIndex: 'chufajuedingriqi', key: 'chufajuedingriqi' },
  { title: '公示日期', align: 'center', dataIndex: 'gongshiriqi', key: 'gongshiriqi' }
]
const columnsCL = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '债务承接人', align: 'center', dataIndex: 'zhaiwuchengjieren', key: 'zhaiwuchengjieren' },
  { title: '债权承接人', align: 'center', dataIndex: 'zhaiquanchengjieren', key: 'zhaiquanchengjieren' },
  { title: '清算负责人', align: 'center', dataIndex: 'qingsuanfuzeren', key: 'qingsuanfuzeren' },
  { title: '清算组成员', align: 'center', dataIndex: 'qingsuanzuchengyuan', key: 'qingsuanzuchengyuan' },
  { title: '地址',align: 'center', dataIndex: 'dizhi', key: 'dizhi' },
  { title: '联系电话', align: 'center', dataIndex: 'lianxidianhua', key: 'lianxidianhua' },
  { title: '清算完结情况', align: 'center', dataIndex: 'qingsuanwanjieqingkuang', key: 'qingsuanwanjieqingkuang' },
  { title: '清算完结日期', align: 'center', dataIndex: 'qingsuanwanjieriqi', key: 'qingsuanwanjieriqi' }
]
const columnsAB = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '流入经营异常名录原因', align: 'center', dataIndex: 'liurujingyingyichangmingluyuanyin', key: 'liurujingyingyichangmingluyuanyin' },
  { title: '列入日期', align: 'center', dataIndex: 'liruriqi', key: 'liruriqi' },
  { title: '列入机关名称', align: 'center', dataIndex: 'lierujiguanmingcheng', key: 'lierujiguanmingcheng' },
  { title: '移出经营异常名录原因', align: 'center', dataIndex: 'yichujingyingyichangmingluyuanyin', key: 'yichujingyingyichangmingluyuanyin' },
  { title: '移出日期',align: 'center', dataIndex: 'yichuriqi', key: 'yichuriqi' },
  { title: '移出机关名称', align: 'center', dataIndex: 'yichujiguanmingcheng', key: 'yichujiguanmingcheng' }
]
const columnsCH = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '登记编号', align: 'center', dataIndex: 'dengjibianhao', key: 'dengjibianhao' },
  { title: '登记日期', align: 'center', dataIndex: 'dengjiriqi', key: 'dengjiriqi' },
  { title: '登记机关', align: 'center', dataIndex: 'dengjijiguan', key: 'dengjijiguan' },
  { title: '被担保债权数额', align: 'center', dataIndex: 'beidanbaozhaiquanshue', key: 'beidanbaozhaiquanshue' },
  { title: '状态',align: 'center', dataIndex: 'zhuangtai', key: 'zhuangtai' },
  { title: '公示日期', align: 'center', dataIndex: 'gongshiriqi', key: 'gongshiriqi' }
]
const columnsKP = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '姓名', align: 'center', dataIndex: 'xingming', key: 'xingming' },
  { title: '身份证号', align: 'center', dataIndex: 'shenfenzhenghao', key: 'shenfenzhenghao' },
  { title: '手机号码', align: 'center', dataIndex: 'shoujihaoma', key: 'shoujihaoma' },
  { title: '职位', align: 'center', dataIndex: 'zhiwei', key: 'zhiwei' }
]

const columnsCT = [
  { title: '序号', width: 80, align: 'center', dataIndex: 'key', key: 'key' },
  { title: '被执行人姓名/名称', align: 'center', dataIndex: 'personName', key: 'personName' },
  { title: '身份证号码/工商注册号', align: 'center', dataIndex: 'idNum', key: 'idNum' },
  { title: '性别', align: 'center', dataIndex: 'sex', key: 'sex' },
  { title: '年龄', align: 'center', dataIndex: 'age', key: 'age' },
  { title: '失信人类型', align: 'center', dataIndex: 'personType', key: 'personType' }
]
export default {
    computed: {
        ...mapGetters(['isAuth', 'loginType', 'orgId'])
    },
    data () {
        return {
            id: '',
            detail: '',
            juList: [],
            columnsJU,
            adList: [],
            columnsAD,
            clList: [],
            columnsCL,
            abList: [],
            columnsAB,
            chList: [],
            columnsCH,
            kpList: [],
            ceditList: [],
            columnsCT,
            columnsKP,
            intermediaryId:'',
        }
    },
    methods: {
        getDetail () {
            this.$store.dispatch('getDiagrepbaseDetail', { DIAGREPBASE_ID: this.id, tm: new Date().getTime() }).then(res => {
                if (res.result === 'success') {
                  if (this.loginType === 'jigou') {
                    this.intermediaryId = res.pd.ENTERPRISE_ID
                    // 获取关键人信息
                    this.getPersonList()
                    // 获取企业司法信息
                    this.getJudicialList()
                    // 获取企业行政处罚
                    this.getAdministrativepenaltyList()
                    // 企业清算信息
                    this.getClearinginfoList()
                    // 企业经营异常名录
                    this.getAbnormitydirectoryList()
                    // 企业动产抵押
                    this.getChattelmortgageList()
                    // 信用查询
                    this.getRiskPersiongageList()
                  }else {
                    // 获取关键人信息
                    this.getPersonList()
                    // 获取企业司法信息
                    this.getJudicialList()
                    // 获取企业行政处罚
                    this.getAdministrativepenaltyList()
                    // 企业清算信息
                    this.getClearinginfoList()
                    // 企业经营异常名录
                    this.getAbnormitydirectoryList()
                    // 企业动产抵押
                    this.getChattelmortgageList()
                    // 信用查询
                    this.getRiskPersiongageList()
                  }
                    if (res.pd != null && res.pd != '') {
                        res.pd.EXEDATE = res.pd.EXEDATE.split(' ')[0]
                        this.detail = res.pd
                    } else {
                        this.detail = ''
                    }
                } else {
                    this.detail = ''
                    this.$message.error('获取诊断详情失败，请稍后重试！')
                }
            })

        },
        // 获取企业司法信息
        getJudicialList () {
          let orId = this.orgId
          if (this.loginType === 'jigou') {
            orId = this.intermediaryId
          }
            this.juList = []
            this.$store.dispatch('getJudicialList',{ENTERPRISE_ID: orId,tm:new Date().getTime()}).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.juList.push({
                                'key': parseInt(i) + 1,
                                'biaoti': datas[i].TITLE,
                                'shenjieshijian': datas[i].CONCLUDE_TIME,
                                'zhuceleixing': datas[i].REGIST_TYPE,
                                'shujuleixing': datas[i].DATA_TYPE,
                                'pipeidu': datas[i].MATCHED_DEGREE,
                                'neironggaiyao': datas[i].CONTENT_SUMMARY
                            })
                        }
                    } else {
                        this.juList = []
                    }
                } else {
                    this.juList = []
                    this.$message.error('系统异常，获取司法信息列表失败，请稍后重试！')
                }
            })
        },
        // 获取企业行政处罚
        getAdministrativepenaltyList () {
          let orId = this.orgId
          if (this.loginType === 'jigou') {
            orId = this.intermediaryId
          }
            this.adList = []
            this.$store.dispatch('getAdministrativepenaltyList',{ENTERPRISE_ID: orId,tm:new Date().getTime()}).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.adList.push({
                                'key': parseInt(i) + 1,
                                'juedingshuwenhao': datas[i].DECISION_NUM,
                                'weifaxingweileixing': datas[i].UNLAWFUL_ACT_TYPE,
                                'chufazhonglei': datas[i].PUNISH_TYPE,
                                'xingzhengchulineirong': datas[i].HANDLING_CONTENT,
                                'juedingjiguanmingcheng': datas[i].DECIDE_OFFICE_NAME,
                                'chufajuedingriqi': datas[i].PENALTY_DECISION_TIME,
                                'gongshiriqi': datas[i].PUBLICITY_TIME
                            })
                        }
                    } else {
                        this.adList = []
                    }
                } else {
                    this.adList = []
                    this.$message.error('系统异常，获取行政处罚列表失败，请稍后重试！')
                }
            })
        },
        // 企业清算信息
        getClearinginfoList () {
          let orId = this.orgId
          if (this.loginType === 'jigou') {
            orId = this.intermediaryId
          }
            this.clList = []
            this.$store.dispatch('getClearinginfoList',{ENTERPRISE_ID: orId,tm:new Date().getTime()}).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.clList.push({
                                'key': parseInt(i) + 1,
                                'zhaiwuchengjieren': datas[i].DEBT_RECEIVER,
                                'zhaiquanchengjieren': datas[i].CREDITOR,
                                'qingsuanfuzeren': datas[i].CLEARING_OFFICER,
                                'qingsuanzuchengyuan': datas[i].GROUP_MEMBER,
                                'dizhi': datas[i].ADDR,
                                'lianxidianhua': datas[i].TEL,
                                'qingsuanwanjieqingkuang': datas[i].SCHEDULE,
                                'qingsuanwanjieriqi': datas[i].END_TIME
                            })
                        }
                    } else {
                        this.clList = []
                    }
                } else {
                    this.clList = []
                    this.$message.error('系统异常，获取清算信息列表失败，请稍后重试！')
                }
            })
        },
        // 企业经营异常名录
        getAbnormitydirectoryList () {
          let orId = this.orgId
          if (this.loginType === 'jigou') {
            orId = this.intermediaryId
          }
            this.abList = []
            this.$store.dispatch('getAbnormitydirectoryList',{ENTERPRISE_ID: orId,tm:new Date().getTime()}).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.abList.push({
                                'key': parseInt(i) + 1,
                                'liurujingyingyichangmingluyuanyin': datas[i].FLOW_INTO_REASON,
                                'liruriqi': datas[i].FLOW_INTO_TIME,
                                'lierujiguanmingcheng': datas[i].FLOW_INTO_OFFICE,
                                'yichujingyingyichangmingluyuanyin': datas[i].SHIFT_OUT_REASON,
                                'yichuriqi': datas[i].SHIFT_OUT_TIME,
                                'yichujiguanmingcheng': datas[i].SHIFT_OUT_OFFICE
                            })
                        }
                    } else {
                        this.abList = []
                    }
                } else {
                    this.abList = []
                    this.$message.error('系统异常，获取清算信息列表失败，请稍后重试！')
                }
            })
        },
        // 企业动产抵押
        getChattelmortgageList () {
          let orId = this.orgId
          if (this.loginType === 'jigou') {
            orId = this.intermediaryId
          }
            this.chList = []
            this.$store.dispatch('getChattelmortgageList',{ENTERPRISE_ID: orId,tm:new Date().getTime()}).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.chList.push({
                                'key': parseInt(i) + 1,
                                'dengjibianhao': datas[i].REGISTER_CODE,
                                'dengjiriqi': datas[i].REGISTER_TIME,
                                'dengjijiguan': datas[i].REGISTER_OFFICE,
                                'beidanbaozhaiquanshue': datas[i].CREDIT_AMOUNT,
                                'zhuangtai': datas[i].STATUS,
                                'gongshiriqi': datas[i].PUBLICITY_TIME
                            })
                        }
                    } else {
                        this.chList = []
                    }
                } else {
                    this.chList = []
                    this.$message.error('系统异常，获取清算信息列表失败，请稍后重试！')
                }
            })
        },
        // 企业人员信息-查询
        getPersonList () {
          let orId = this.orgId
          if (this.loginType === 'jigou') {
            orId = this.intermediaryId
          }
            this.kpList = []
            this.$store.dispatch('getPersonList', { showCount: 500, currentPage: 1, ENTERPRISE_ID: orId }).then(res => {
                if (res.result === 'success') {
                    let datas = res.varList
                    if (datas.length != 0) {
                        for (let i in datas) {
                            this.kpList.push({
                                'key': parseInt(i) + 1,
                                'xingming': datas[i].PERSONNAME,
                                'shenfenzhenghao': datas[i].PERSONCODE,
                                'shoujihaoma': datas[i].PHONE,
                                'zhiwei': datas[i].PERSONPROPER
                            })
                        }
                    } else {
                        this.kpList = []
                    }
                } else {
                    this.kpList = []
                    this.$message.error('服务器异常，获取关键人信息列表失败，请稍后重试！')
                }
            })
        },
      // 信用信息查询
      getRiskPersiongageList () {
        let orId = this.orgId
        if (this.loginType === 'jigou') {
          orId = this.intermediaryId
        }
        this.ceditList = []
        this.$store.dispatch('getRiskPersiongageList',{ENTERPRISE_ID: orId,tm:new Date().getTime()}).then(res => {
          if (res.result === 'success') {
            let datas = res.varList
            if (datas.length != 0) {
              for (let i in datas) {
                this.ceditList.push({
                  'key': parseInt(i) + 1,
                  'personName': datas[i].person_name,
                  'idNum': datas[i].id_num,
                  'sex': datas[i].sex,
                  'age': datas[i].age,
                  'personType': datas[i].person_type,
                })
              }
            } else {
              this.ceditList = []
            }
          } else {
            this.ceditList = []
            this.$message.error('系统异常，获取清算信息列表失败，请稍后重试！')
          }
        })
      },
      fh(){
        this.$router.push({name:"DiagnosisHistory"})
      }
    },
    created () {
        if (this.loginType === 'qiye' && this.isAuth === 'N') {
            this.$message.info('请先完成企业认证或个体认证后才能进入')
            this.$router.push('/PersonalLayout/WareHouse')
            return false
        }
        this.id = this.$route.query.id
          this.getDetail()

    }
}
</script>

<style lang="less" scoped>
.main-box{
    width: @main-width-base;
    margin:20px auto;
    .pd-box{
        padding: 15px;
        background-color: @color-ff;
        box-shadow: 0px 0px 10px #929292;
        border-radius: 5px;
        margin: 20px 0;
        position: relative;
        .title{
            font-size: @font-lg;
            padding: 0px 10px;
            padding-bottom: 15px;
            border-bottom: @border-base;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-top: 15px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .con-item{
                width: 49%;
            }
        }
        .contents{
            text-align: center;
            .ant-btn{
                padding: 0 120px;
                height: 40px;
                color: @color-ff;
                background: -webkit-linear-gradient(to left,@color-blue,@color-purple); /* Safari 5.1-6.0 */
                background: -o-linear-gradient(to left,@color-blue,@color-purple); /* Opera 11.1-12.0 */
                background: -moz-linear-gradient(to left,@color-blue,@color-purple); /* Firefox 3.6-15 */
                background: linear-gradient(to left,@color-blue,@color-purple); /* 标准语法 */
            }
        }
        .content-history{
            margin-top: 20px;
            font-size: 16px;
        }
    }
    .pd-box::before{
        content: '';
        position: absolute;
        left: -3px;
        top: 10px;
        width: 6px;
        height: 40px;
        background: -webkit-linear-gradient(to top,@color-blue,@color-purple); /* Safari 5.1-6.0 */
        background: -o-linear-gradient(to top,@color-blue,@color-purple); /* Opera 11.1-12.0 */
        background: -moz-linear-gradient(to top,@color-blue,@color-purple); /* Firefox 3.6-15 */
        background: linear-gradient(to top,@color-blue,@color-purple); /* 标准语法 */
    }
}
</style>
